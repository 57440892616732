import React, { useEffect, useState, useContext } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import ls from 'local-storage';
import { useLocation } from 'react-use';

import { getUrl, APPS, getAppTokenName } from '@axeedge/go-shared-utils';
import { ErrorPage } from '@axeedge/go-shared-components'

import { ERROR, FORCED_LOGOUT } from '../services/graphql';
import { AuthContext } from '../../../services/auth/AuthProvider';

import { APP_NAME, APP_NAME_US } from '../../../services/constants';

const RouteCapture = ({ children }) => {

    const [targetRoute, setTargetRoute] = useState('');
    const client = useApolloClient();
    const { data } = useQuery(FORCED_LOGOUT);
    const { data: clientError } = useQuery(ERROR);
    const { setCurrentUser } = useContext(AuthContext);

    const locationState = useLocation();

    useEffect(() => {
        setTargetRoute(locationState.pathname);
    }, [setTargetRoute, locationState]);


    const forceLogout = redirect => {

       ls.remove('go_student_user');
       ls.remove(getAppTokenName(ls('country') === 'US' ? APP_NAME_US : APP_NAME));

        client.clearStore();
        setCurrentUser(null);
        if (redirect) {
            window.location.href = `${getUrl(APPS.go_portal_pupil)}/redirectAfterLogin/${ls('country') === 'US' ? APP_NAME_US : APP_NAME}?targetUrl=${targetRoute}`;
        } else {
            window.location.href = `${getUrl(APPS.go_portal_pupil)}`;
        }
    }
    
    useEffect(() => {
        if (ls('go_student_user') && ls('go_student_user') === 'undefined') {
            forceLogout(false);
        }
    });

    if (data && data.forcedLogout) {
        forceLogout(true)
    }

    if (clientError && clientError.error) {
        return <ErrorPage />
    }

    return children;
}

export default RouteCapture;