import React from 'react';
import { navigate } from '@reach/router';

const LogoutButton = ({ children, classes }) => {

    return (
        <button
            className={classes}
            onClick={() => {
                navigate('/logout');
            }}
        >
            {children}
        </button>
    );
}

export default LogoutButton;