// Queries and mutations for the Home module
import { gql } from 'apollo-boost';

export const LOGIN = gql`
query currentStudent {
    currentStudent {
        id
        active
        avatarJson
        firstName
        name
        loginCode
        expiredToken
        updatedAt
        classes {
            id
            name
        }
    }
}
`;

