import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useEffectOnce } from 'react-use';
import ls from 'local-storage';
import { Redirect, Router } from '@reach/router';

import { Loader } from '@axeedge/go-teacher-components';
import { getAppTokenName } from '@axeedge/go-shared-utils';
import { ErrorPage, Ssl } from '@axeedge/go-shared-components';

import { AuthContext } from '../../../services/auth/AuthProvider';
import { LOGGED_IN } from '../services/graphql';
import Logout from '../../Logout';
import { APP_NAME, APP_NAME_US } from '../../../services/constants';
import Dashboard from '../../Dashboard';
import LoggedInRouter from './LoggedInRouter';
import Avatar from '../../Avatar';
import Boomer from '../../Boomer';
import Rewards from '../../Rewards';
import RouteCapture from './RouteCapture';
import Login from '../../Login';
import RedirectAfterLogin from '../../Login/scenes/RedirectAfterLogin';
import Impersonate from '../../../components/Impersonate';
import WondeLoggingIn from '../../Login/scenes/WondeLoggingIn';
import BetaWondeLogin from '../../Login/BetaWondeLogin';
import Survey from '../../Survey';

const RouterAuthQuery = () => {
    const { data, error, loading } = useQuery(LOGGED_IN);
    const [ready, setReady] = useState(false);
    const auth = useContext(AuthContext);

    useEffect(() => {
        if (ls(getAppTokenName(ls('country') === 'US' ? APP_NAME_US : APP_NAME)) && !auth.currentUser && ls('go_student_user') && ls('go_student_user') !== 'undefined') {
            auth.setCurrentUser(JSON.parse(ls('go_student_user')));
        }
    }, [auth]);

    useEffectOnce(() => {
        if (ls(getAppTokenName(ls('country') === 'US' ? APP_NAME_US : APP_NAME)) && !auth.currentUser && ls('go_student_user') && ls('go_student_user') !== 'undefined') {
            auth.setCurrentUser(JSON.parse(ls('go_student_user')));
        }
        setReady(true);
    });

    const renderContent = () => {
        if (error) {
            return <p>{error.message}</p>
        }

        if (loading) {
            return <Loader />
        }

        if (data && ready) {
            return (
                <Router>
                    <Impersonate path='/impersonate/:token' />
                    <WondeLoggingIn path='/wondeLogin' />
                    <RouteCapture path='/'>
                        {
                            auth.currentUser ? (
                                <LoggedInRouter path='/' default>
                                    <RedirectAfterLogin path='/redirectAfterLogin/:app' />
                                    <Logout path='/logout' />
                                    <Avatar path='/avatar' />
                                    <Boomer path='/boomer' />
                                    <Dashboard path='/' default />
                                    <Rewards path='/rewards' />
                                    {/* <Survey path='/survey' /> */}

                                </LoggedInRouter>
                            ) : (
                                <Fragment>
                                    <Login path='/' default />
                                        <BetaWondeLogin path='/beta' />
                                    <Redirect from='/logout' to='/' noThrow />
                                    <RedirectAfterLogin path='/redirectAfterLogin/:app' />
                                    <ErrorPage path='/error' />
                                </Fragment>
                            )
                        }
                    </RouteCapture>
                </Router>
            )
        }
    }

    return (
        <Fragment>
            <Ssl />
            {renderContent()}
        </Fragment>
    );

}

export default RouterAuthQuery;
