import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Loader } from '@axeedge/go-pupil-components';

import styles from './Avatar.module.scss';
import Edit from './components/Edit';
import { GET_AVATAR_QUERY } from './services/graphql';

function Avatar() {

  const { data, error, loading } = useQuery(GET_AVATAR_QUERY);

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <p>{error.message}</p>
  }

  if (data) {
    const avatar = JSON.parse(data.currentStudent.avatarJson);
    return (
      <div className={styles.container}>
        <Edit student={data.currentStudent} avatar={avatar} />
      </div>
    )
  }
}

export default Avatar;