import React, {useState, useEffect, useContext} from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { confirmAlert } from 'react-confirm-alert';
import _ from 'lodash';
import { navigate } from '@reach/router';
import Badges from './Badges';
import Categories from './Categories';
import Items from './Items';
import Tiles from './Tiles';
import OwnedTiles from './OwnedTiles';
import Alert from './Alert';
import Confirm from './Confirm';
import BoomerBucks from './BoomerBucks';
import BadgeInfo from './BadgeInfo';
import ItemPreview from './ItemPreview';
import DrawBoomer from './DrawBoomer';
import SpotlightLeft from '../../../images/spotlight-left.png';
import SpotlightRight from '../../../images/spotlight-right.png';
import Coins from '../../Dashboard/images/gem.svg';
import SmallLoader from '../../../images/small-loader.svg'

import { 
    BODY, 
    BOOMER_FALLBACK_DATA,
    isWearing,
    previewItem, 
    previewTile,
    removeItem 
} from '../../../services/utils/boomer';

import { 
    BOOMER_STORE_QUERY,
    SAVE_BOOMER_ITEMS,
    BUY_BOOMER_ITEM,
    GET_BOOMER_QUERY,
    GEMS_QUERY
} from '../services/graphql';

import {
    RANK1,
    RANK2
} from '../../../services/utils/boomer';

import { AuthContext } from '../../../services/auth/AuthProvider';


const Container = ({ data: { boomerAvatarJson, badgesList, availableGems, currentBadge, avatarItems, rank }, history }) => {
    const client = useApolloClient();
    const avatarData = boomerAvatarJson !== '[]' ? JSON.parse(boomerAvatarJson) : BOOMER_FALLBACK_DATA;
    const [containerState, setContainerState] = useState({
        boomerImgData: null,
        boomerReset: avatarData,
        boomerWearing: avatarData,
        inventoryType: 'store',
        rankInfo: {},
        saving: false,
        selectedBadge: null,
        selectedBadgeUrl: null,
        selectedCategory: BODY,
        selectedItem: null,
        showBadgeInfo: null,
        showRankInfo: null,
    });
    const [allItems, setAllItems] = useState(null)

    const { currentUser, setCurrentUser } = useContext(AuthContext);
    /*const [selectBadge] = useMutation(SELECT_BADGE, {
        awaitRefetchQueries: true,
        onCompleted: () => setContainerState(prevState => {
            return { 
                ...prevState, 
                saving: false,
                boomerWearing: prevState.boomerReset,
                selectedBadge: null,
                selectedBadgeUrl: null,
                selectedItem: null
            }
        }),
        refetchQueries: () => [
            { query: BOOMER_STORE_QUERY },
            { query: MAIN_LOGIN_QUERY }
        ]
    });*/

    let showActionButtons = !_.isEqual(containerState.boomerWearing, containerState.boomerReset);

    useEffect(() => {
        showActionButtons = !_.isEqual(containerState.boomerWearing, containerState.boomerReset);
    });

    const toggleBadgeInfo = () => {
        setContainerState(prevState => {
            return {
                ...prevState,
                showBadgeInfo: !containerState.showBadgeInfo,
                showRankInfo: false,
            }
        });
    }

    const toggleRankInfo = (rank) => {
        let newRankInfo = {};
        if (rank.points >= RANK2) {
            newRankInfo = {
                name: 'Rank 2',
                url: rank.url2,
                message: 'Earn ' + RANK2 + ' points!'
            }
        } else {
            newRankInfo = {
                name: 'Rank 1',
                url: rank.url2,
                message: 'Earn ' + RANK1 + ' points!'
            }            
        }
        setContainerState(prevState => {
            return {
                ...prevState,
                rankInfo: newRankInfo,
                showBadgeInfo: false,
                showRankInfo: !containerState.showRankInfo,
            }
        });
    }

    const onSelectedCategory = (selectedCategory) => {
        setContainerState(prevState => {
            return { 
                ...prevState,
                boomerWearing: containerState.boomerReset,
                selectedCategory,
                selectedItem: null 
            }
        });
    }

    const onSelectedBadge = (selectedBadge, selectedBadgeUrl) => {
        setContainerState(prevState => {
            return {
                ...prevState,
                selectedBadge,
                selectedBadgeUrl,
                showBadgeInfo: false
            }
        });
    }

    const onSelectedItem = (selectedItem) => {
        setContainerState(prevState => {
            return {
                ...prevState,
                boomerWearing: previewItem(containerState.boomerWearing, selectedItem), 
                selectedItem: {
                    ...selectedItem,
                    wearing: true,
                    tileOwned: selectedItem.owned
                } 
            }
        });
    }

    const onSelectedTile = (tile, tileOwned) => {
        setContainerState(prevState => {
            return {
                ...prevState,
                boomerWearing: previewTile(containerState.boomerWearing, containerState.selectedItem, tile),
                selectedItem: {
                    ...containerState.selectedItem,
                    tile,
                    tileOwned
                }
            }
        });
    }

    const onRemoveItem = () => {
        setContainerState(prevState => {
            return {
                ...prevState,
                boomerWearing: removeItem(containerState.boomerWearing, containerState.selectedItem.xmlId),
                selectedItem: {
                    ...containerState.selectedItem,
                    tile: -1
                }
            }
        });
    }

    const onSetBoomerImg = (boomerImgData) => {
        setContainerState(prevState => {
            return {
                ...prevState,
                boomerImgData
            }
        });
    }

    const onSwitchInventory = (type, category) => {
        setContainerState(prevState => {
            return {
                ...prevState,
                inventoryType: type,
                selectedCategory: category,
                boomerWearing: containerState.boomerReset,
                selectedBadge: null,
                selectedBadgeUrl: null,
                selectedItem: null 
            }
        });
    }

    const onBuy = () => {
        if (containerState.selectedItem.price > availableGems) {
            showCantAffordAlert();
        } else {
            showBuyConfirm();
        }
    }

    const onSave = (isPurchase) => {
        const { boomerWearing, selectedItem } = containerState;

        const items = _.map(avatarItems, item => { 
            if (isWearing(boomerWearing, item)) {
                return {
                    xmlId: item.xmlId,
                    gender: item.gender,
                    type: item.type,
                    xOffset: item.xOffset,
                    yPos: item.yPos,
                    tile: Number(_.find(boomerWearing.items, ['xmlId', item.xmlId]).tile)
                }
            }
            return null
        });

        if (isPurchase) {
            items.push({
                xmlId: selectedItem.xmlId,
                gender: selectedItem.gender,
                type: selectedItem.type,
                xOffset: selectedItem.xOffset,
                yPos: selectedItem.yPos,
                tile: Number(_.find(boomerWearing.items, ['xmlId', selectedItem.xmlId]).tile)
            })
        }

        items.push({
            xmlId: `${boomerWearing.body.body_id}`,
            gender: "female",
            type: BODY,
            xOffset: null,
            yPos: null,
            tile: 1
        });

        const newItems = [];

        items.forEach(item => {
            if (item) {
                newItems.push({...item, gender: getGender(item.gender), type: getType(item.type)})
            }
        })




        setContainerState(prevState => {
            return {
                ...prevState,
                saving: true
            }
        });

        client.mutate({
            mutation: SAVE_BOOMER_ITEMS,
            refetchQueries: [
                { query: BOOMER_STORE_QUERY },
                { query: GET_BOOMER_QUERY }
            ],
            variables: {
                items: _.compact(newItems),
                data: containerState.boomerImgData
            },
            update: () => {
                setContainerState(prevState => {
                    return {
                        ...prevState,
                        boomerReset: containerState.boomerWearing,
                        saving: false,
                        selectedItem: {
                            ...selectedItem,
                            owned: true
                        }
                    }
                });
                setCurrentUser({
                    ...currentUser,
                    availableGems: isPurchase ? currentUser.availableGems - containerState.selectedItem.price : currentUser.availableGems,
                    boomerAvatarUrl: updateQueryStringParameter(currentUser.boomerAvatarUrl, 'v',Math.floor(Math.random() * 7000)),
                })
            }
        });

    }

    const updateQueryStringParameter = (uri, key, value) => {
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf('?') !== -1 ? "&" : "?";
        if (uri.match(re)) {
          return uri.replace(re, '$1' + key + "=" + value + '$2');
        }
        else {
          return uri + separator + key + "=" + value;
        }
      }

    const onReset = () => {
        setContainerState(prevState => {
            return {
                ...prevState,
                boomerWearing: containerState.boomerReset,
                selectedBadge: null,
                selectedBadgeUrl: null,
                selectedItem: null 
            }
        });
    }

    const showBuyConfirm = () => {
        const { selectedItem } = containerState;

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <Confirm 
                        cancelMessage={"Cancel"}
                        className={"store__purchase-overlay"}
                        message={<span className="store__purchase-overlay-message">Are you sure you want to purchase this item for <img className="store__purchase-overlay-coins" alt="Coins" src={Coins} />{selectedItem.price}?</span>}
                        onClose={onClose} 
                        onConfirm={confirmPurchase} 
                        title={"Confirm Purchase"}
                    >
                        <span className="store__purchase-overlay-message">Are you sure you want to purchase this item for: <img className="store__purchase-overlay-coins" alt="Coins" src={Coins} />{selectedItem.price}?</span>
                    </Confirm>
                );
            }
        });
    }

    const showCantAffordAlert = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <Alert 
                        message={"You don't have enough gems for this item!"}
                        onClose={onClose} 
                        title={"Uh oh!"}
                        className={"store__purchase-overlay"}
                    />
                )
            }
        });
    }

    const confirmPurchase = () => {
        const { selectedItem } = containerState;
        client.mutate({
            mutation: BUY_BOOMER_ITEM,
            variables: {
                itemXmlId: selectedItem.xmlId, 
                gender: getGender(selectedItem.gender), 
                itemType: getType(selectedItem.type),
                tileId: parseInt(selectedItem.tile)
            },
            awaitRefetchQueries: true,
            refetchQueries: () => [
                {
                    query: GEMS_QUERY
                }
            ],
            update: () => {
                onSave(true)
            }
        });

        setContainerState(prevState => {
            return {
                ...prevState,
                saving: true
            }
        });
    }

    const getGender = (gender) => {
        switch(gender) {
            case 'female':
                return 1
              break;
            case 'male':
                return 2
              break;
            case 'mixed':
                return 3
                break;
            default:
                return
          }      
    }

    const getType = (type) => {
        switch(type) {
            case 'body':
                return 1
                break;
            case 'hair':
                return 2
                break;
            case 'top':
                return 3
                break;
            case 'bottom':
                return 4
                break;
            case 'accessory':
                return 5
                break;
            case 'pet':
                return 6
                break;
            default:
                return
          }  
    }

    const selectBadgeButton = () => {
        return (
            <button
                className="button button--bordered"
                onClick={() => {
                    /*selectBadge({ variables: { badgeId: containerState.selectedBadge } });*/
                    setContainerState(prevState => {
                        return {
                            ...prevState,
                            saving: true
                        }
                    });
                }}
            >
                Select
            </button>
        );
    }

    return (
        <> <div className="store">
        {
            containerState.saving && <div className="store__loading"><img src={SmallLoader} /></div>
        }
        <div className="store__mob-header">
            <button className="button button--outline store__mob-button" onClick={() => window.history.back()}>Back</button>
            <span className="store__header-text">Customize your boomer</span>
            <div className="store__header-bucks">
                <img src={Coins} className="store__header-coins" alt="Boomer Bucks" />
                <BoomerBucks />
            </div>
        </div>
        {
            containerState.showBadgeInfo &&
            <BadgeInfo badge={currentBadge} close={() => toggleBadgeInfo()} mutation="store" />
        }
        {
            containerState.showRankInfo &&
            <BadgeInfo 
                badge={containerState.rankInfo} 
                close={() => {
                    setContainerState(prevState => {
                        return { ...prevState, showRankInfo: false }
                    });
                }} 
                mutation="store" 
            />
        }
        <div className="store__preview">
            <div className="store__header u-visible-desktop">Boomer Preview</div>
            <img src={SpotlightLeft} alt="Spotlight" className="store__spotlight"/>
            <img src={SpotlightRight} alt="Spotlight" className="store__spotlight store__spotlight--right"/>
            {allItems &&
            <DrawBoomer boomerData={containerState.boomerWearing} setBoomerImage={onSetBoomerImg} badge={containerState.selectedBadgeUrl} allItems={allItems}/>}
            {currentBadge &&
                <div className="preview-badge" onClick={() => toggleBadgeInfo()}>
                    <img src={currentBadge.url} className="preview-badge__badge" alt="Badge Preview"/>
                </div>
            }
            {/*rank.points >= RANK1  &&
                 <div className="preview-badge preview-badge--rank" onClick={() => toggleRankInfo(rank)}>
                    <img src={rank.url2} className="preview-badge__badge" alt="Badge Preview"/>
                </div>                           
            */}
        </div>
        <div className="store__controls">                        
            <div>
                <div className="store__wallet">
                    <p className="store__wallet-text">You have</p><img src={Coins} className="store__coins" alt="Boomer Bucks" />
                    <p className="store__bucks">
                        <BoomerBucks />
                    </p>
                    <p className="store__wallet-text">to spend</p>
                </div>
                <ul className="store__tabs">
                    <li className={`store__tab ${containerState.inventoryType === "store" && "store__tab--active"}`} onClick={() => onSwitchInventory("store", BODY)}>Store</li>
                    <li className={`store__tab ${containerState.inventoryType === "owned" && "store__tab--active"}`} onClick={() => onSwitchInventory("owned", 'OWNED')}>Your Stuff</li>
                    {/* <li className={`store__tab ${containerState.inventoryType === "badges" && "store__tab--active"}`} onClick={() => onSwitchInventory("badges", 'BADGES')}>Badges</li> */}
                </ul>
                {
                    containerState.inventoryType === "store" && <Categories onSelectCategory={onSelectedCategory} selectedCategory={containerState.selectedCategory} />
                }
            </div>
            <div className="store__items">
                {
                    (showActionButtons && !containerState.saving) || (containerState.selectedBadge && !containerState.saving)  ?
                    (
                        <div className="store__actions">
                            <button onClick={() => onReset()} className="button button--outline">Cancel</button>

                            {
                                containerState.selectedBadge ? 
                                selectBadgeButton() :
                                containerState.selectedItem.type === BODY || containerState.selectedItem.tileOwned || containerState.selectedCategory === 'OWNED' ?
                                <button onClick={() => onSave()} className="button button--bordered">Save</button> :
                                <button onClick={() => onBuy()} className="button button--bordered">Buy</button>
                            }
                        </div>
                    ) : null
                }
                <ul className={`store__items-list ${(showActionButtons && !containerState.saving) || (containerState.selectedBadge && !containerState.saving) ? 'store__items-list--padded' : null}`}>
                    
                    {
                        containerState.selectedItem && containerState.selectedItem.type !== BODY ?
                        containerState.selectedCategory === 'OWNED' ?
                        <OwnedTiles item={containerState.selectedItem}
                        onRemoveItem={onRemoveItem}
                        onSelectTile={onSelectedTile}
                         />
                        :
                        <Tiles 
                            item={containerState.selectedItem} 
                            onRemoveItem={onRemoveItem}
                            onSelectTile={onSelectedTile}
                            selectedCategory={containerState.selectedCategory} 
                        /> :
                        <Items
                            setAllItems={setAllItems} 
                            onSelectItem={onSelectedItem} 
                            ownedItems={avatarItems} 
                            selectedCategory={containerState.selectedCategory} 
                            wearing={containerState.boomerWearing}
                        />
                    }
                    {containerState.selectedCategory === 'BADGES' ? 
                        <Badges badges={badgesList} onSelectedBadge={onSelectedBadge} selected={containerState.selectedBadge} />
                        : 
                        null
                    }
                </ul>
                
            </div>
        </div>
    </div></>
    )
}

export default Container