import React, {useState, useRef} from 'react';
import { useMutation } from '@apollo/react-hooks';
import styles from '../Avatar.module.scss';
import cx from 'classnames';
import { Skins, Eyes, Mouths } from "@axeedge/go-shared-components";
import { GET_AVATAR_QUERY, SAVE_AVATAR } from '../services/graphql';
import { useTranslation } from 'react-i18next';

const Edit = ( {avatar, student} ) => {

  const { t } = useTranslation();

  const [bgColor, setBgColor] = useState(avatar.bgColor)
  const [eyeColor, setEyeColor] = useState(avatar.eyeColor)
  const [skinSettings, setSkinSettings] = useState(avatar.skinSettings)
  const [SelectedSkin, setSkinImg] = useState(avatar.skin);
  const [SelectedEyes, setEyesImg] = useState(avatar.eyes);
  const [SelectedMouth, setMouthImg] = useState(avatar.mouth);
  const Skin = Skins[`MemoSkin${SelectedSkin}`];
  const Eye = Eyes[`MemoEyes${SelectedEyes}`];
  const Mouth = Mouths[`MemoMouth${SelectedMouth}`];
  const [filter, setFilter] = useState("BG");

  const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const [saveAvatar, { loading }] = useMutation(SAVE_AVATAR, {
    awaitRefetchQueries: true,
    refetchQueries: () => [
        {
            query: GET_AVATAR_QUERY,
            variables: {
                id: student.id,
            }
        }
    ]
});

  const avatarSVG = useRef(null);

  const generateRandom = () => {
    setBgColor({
      r: getRandomInt(1,255),
      g: getRandomInt(1,255),
      b: getRandomInt(1,255)
    })
    setEyeColor({
      r: getRandomInt(1,255),
      g: getRandomInt(1,255),
      b: getRandomInt(1,255)
    })
    setSkinSettings({
      opacity: `0.${getRandomInt(1,50)}`,
      color: getRandomInt(1,2) === 1 ? '#000' : '#FFF'
    })
    setSkinImg(getRandomInt(1,8));
    setEyesImg(getRandomInt(1,8));
    setMouthImg(getRandomInt(1,8));
  }

  const save = () => {
    const avatarData = {
      eyeColor: eyeColor,
      bgColor: bgColor ,
      skinSettings: skinSettings,
      skin: SelectedSkin,
      eyes: SelectedEyes,
      mouth: SelectedMouth,
      svg: 'svg element will go here'
    }
    saveAvatar({
      variables: {
        avatarJson: JSON.stringify(avatarData)
      }
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.imageView}>
        <svg viewBox="0 0 600 600" className={styles.svg} ref={avatarSVG}>
          <rect width="100%" height="100%" fill={`rgb(${bgColor.r},${bgColor.g},${bgColor.b})`}/>
          <Skin skinsettings={skinSettings}/>
          <Mouth/>
          <Eye eyecolor={eyeColor}/>
        </svg>
      </div>
      <div className={cx(styles.filters, styles.flex)}>
        <button className={cx(styles.filter, filter === 'BG' && styles.filterActive)} onClick={() => {setFilter('BG')}}>{t('colour')}</button>
        <button className={cx(styles.filter, filter === 'SKIN' && styles.filterActive)} onClick={() => {setFilter('SKIN')}}>{t('skin')}</button>
        <button className={cx(styles.filter, filter === 'EYES' && styles.filterActive)} onClick={() => {setFilter('EYES')}}>{t('eyes')}</button>
        <button className={cx(styles.filter, filter === 'MOUTH' && styles.filterActive)} onClick={() => {setFilter('MOUTH')}}>{t('mouth')}</button>
      </div>
      {filter === 'BG' &&
        <div className={styles.options}>
          <div className={cx(styles.flex, styles.colors)}>
            <button className={styles.color} style={{background: '#4A90E2'}} onClick={() => {setBgColor({r: 74, g: 144, b: 226})}}></button>
            <button className={styles.color} style={{background: '#51B364'}} onClick={() => {setBgColor({r: 81, g: 179, b: 100})}}></button>
            <button className={styles.color} style={{background: '#F8D01C'}} onClick={() => {setBgColor({r: 248, g: 208, b: 28})}}></button>
            <button className={styles.color} style={{background: '#F27A11'}} onClick={() => {setBgColor({r: 242, g: 122, b: 17})}}></button>
            <button className={styles.color} style={{background: '#F34747'}} onClick={() => {setBgColor({r: 243, g: 71, b: 71})}}></button>
            <button className={styles.color} style={{background: '#F08BB9'}} onClick={() => {setBgColor({r: 240, g: 139, b: 185})}}></button>
            <button className={styles.color} style={{background: '#696CD4'}} onClick={() => {setBgColor({r: 105, g: 108, b: 212})}}></button>
            <button className={styles.color} style={{background: '#6D6D6D'}} onClick={() => {setBgColor({r: 109, g: 109, b: 109})}}></button>
            <button className={styles.color} style={{background: '#F5F5F5'}} onClick={() => {setBgColor({r: 245, g: 245, b: 245})}}></button>
          </div>
          <div>
            <div className={styles.sliderSet}> 
              <label className={styles.sliderLabel}>{t('red')}</label>
              <input type="range" min="1" max="255" value={bgColor.r} onChange={(e) => setBgColor({...bgColor, r: e.currentTarget.value})} />
            </div>
            <div className={styles.sliderSet}> 
              <label className={styles.sliderLabel}>{t('green')}</label>
              <input type="range" min="1" max="255" value={bgColor.g} onChange={(e) => setBgColor({...bgColor, g: e.currentTarget.value})} />
            </div>
            <div className={styles.sliderSet}> 
              <label className={styles.sliderLabel}>{t('blue')}</label>  
              <input type="range" min="1" max="255" value={bgColor.b} onChange={(e) => setBgColor({...bgColor, b: e.currentTarget.value})} />
            </div>
          </div>        
        </div>
      }
      {filter === 'SKIN' &&
        <div className={styles.options}>
          <div className={cx(styles.flex, styles.cells)}>
            <button className={styles.cell} style={{backgroundColor: `rgb(${bgColor.r},${bgColor.g},${bgColor.b})`}} onClick={() => setSkinImg(1)}><svg viewBox="0 0 600 600" className={styles.svg}><Skins.MemoSkin1 skinsettings={skinSettings} /></svg></button>
            <button className={styles.cell} style={{backgroundColor: `rgb(${bgColor.r},${bgColor.g},${bgColor.b})`}} onClick={() => setSkinImg(2)}><svg viewBox="0 0 600 600" className={styles.svg}><Skins.MemoSkin2 skinsettings={skinSettings} /></svg></button>
            <button className={styles.cell} style={{backgroundColor: `rgb(${bgColor.r},${bgColor.g},${bgColor.b})`}} onClick={() => setSkinImg(3)}><svg viewBox="0 0 600 600" className={styles.svg}><Skins.MemoSkin3 skinsettings={skinSettings} /></svg></button>
            <button className={styles.cell} style={{backgroundColor: `rgb(${bgColor.r},${bgColor.g},${bgColor.b})`}} onClick={() => setSkinImg(4)}><svg viewBox="0 0 600 600" className={styles.svg}><Skins.MemoSkin4 skinsettings={skinSettings} /></svg></button>
            <button className={styles.cell} style={{backgroundColor: `rgb(${bgColor.r},${bgColor.g},${bgColor.b})`}} onClick={() => setSkinImg(5)}><svg viewBox="0 0 600 600" className={styles.svg}><Skins.MemoSkin5 skinsettings={skinSettings} /></svg></button>
            <button className={styles.cell} style={{backgroundColor: `rgb(${bgColor.r},${bgColor.g},${bgColor.b})`}} onClick={() => setSkinImg(6)}><svg viewBox="0 0 600 600" className={styles.svg}><Skins.MemoSkin6 skinsettings={skinSettings} /></svg></button>
            <button className={styles.cell} style={{backgroundColor: `rgb(${bgColor.r},${bgColor.g},${bgColor.b})`}} onClick={() => setSkinImg(7)}><svg viewBox="0 0 600 600" className={styles.svg}><Skins.MemoSkin7 skinsettings={skinSettings} /></svg></button>
            <button className={styles.cell} style={{backgroundColor: `rgb(${bgColor.r},${bgColor.g},${bgColor.b})`}} onClick={() => setSkinImg(8)}><svg viewBox="0 0 600 600" className={styles.svg}><Skins.MemoSkin8 skinsettings={skinSettings} /></svg></button>
          </div>
          <div className={styles.sliders}>
            <div className={styles.sliderSet}>
              <label className={styles.sliderLabel}>{t('transparency')}</label>
              <input type="range" min="0.05" max="0.5" step="0.01" value={skinSettings.opacity} onChange={(e) => {setSkinSettings({...skinSettings, opacity: e.currentTarget.value})}} />
            </div>
            <div className={cx(styles.skinColors, styles.colors)}>
            <button className={cx(styles.color, styles.skinColorLeft)} style={{background: '#000'}} onClick={(e) => {setSkinSettings({...skinSettings, color: "#000"})}}></button>
            <button className={styles.color} style={{background: '#FFF'}} onClick={(e) => {setSkinSettings({...skinSettings, color: "#FFF"})}}></button>
            </div>
          </div>
        </div>
      }
      {filter === 'EYES' &&
        <div className={styles.options}>
        <div className={cx(styles.flex, styles.cells)}>
          <button className={styles.cell} style={{backgroundColor: `rgb(${bgColor.r},${bgColor.g},${bgColor.b})`}} onClick={() => setEyesImg(1)}><svg viewBox="0 0 600 600" className={styles.svg}><Eyes.MemoEyes1 eyecolor={eyeColor}/></svg></button>
          <button className={styles.cell} style={{backgroundColor: `rgb(${bgColor.r},${bgColor.g},${bgColor.b})`}} onClick={() => setEyesImg(2)}><svg viewBox="0 0 600 600" className={styles.svg}><Eyes.MemoEyes2 eyecolor={eyeColor}/></svg></button>
          <button className={styles.cell} style={{backgroundColor: `rgb(${bgColor.r},${bgColor.g},${bgColor.b})`}} onClick={() => setEyesImg(3)}><svg viewBox="0 0 600 600" className={styles.svg}><Eyes.MemoEyes3 eyecolor={eyeColor}/></svg></button>
          <button className={styles.cell} style={{backgroundColor: `rgb(${bgColor.r},${bgColor.g},${bgColor.b})`}} onClick={() => setEyesImg(4)}><svg viewBox="0 0 600 600" className={styles.svg}><Eyes.MemoEyes4 eyecolor={eyeColor}/></svg></button>
          <button className={styles.cell} style={{backgroundColor: `rgb(${bgColor.r},${bgColor.g},${bgColor.b})`}} onClick={() => setEyesImg(5)}><svg viewBox="0 0 600 600" className={styles.svg}><Eyes.MemoEyes5 eyecolor={eyeColor}/></svg></button>
          <button className={styles.cell} style={{backgroundColor: `rgb(${bgColor.r},${bgColor.g},${bgColor.b})`}} onClick={() => setEyesImg(6)}><svg viewBox="0 0 600 600" className={styles.svg}><Eyes.MemoEyes6 eyecolor={eyeColor}/></svg></button>
          <button className={styles.cell} style={{backgroundColor: `rgb(${bgColor.r},${bgColor.g},${bgColor.b})`}} onClick={() => setEyesImg(7)}><svg viewBox="0 0 600 600" className={styles.svg}><Eyes.MemoEyes7 eyecolor={eyeColor}/></svg></button>
          <button className={styles.cell} style={{backgroundColor: `rgb(${bgColor.r},${bgColor.g},${bgColor.b})`}} onClick={() => setEyesImg(8)}><svg viewBox="0 0 600 600" className={styles.svg}><Eyes.MemoEyes8 eyecolor={eyeColor}/></svg></button>
        </div>
          <div className={styles.sliders}>
          <div className={cx(styles.flex, styles.colors)}>
            <button className={styles.color} style={{background: 'rgb(255,185,0)'}} onClick={() => {setEyeColor({r: 255, g: 185, b: 0})}}></button>
            <button className={styles.color} style={{background: 'rgb(247,134,43)'}} onClick={() => {setEyeColor({r: 247, g: 134, b: 43})}}></button>
            <button className={styles.color} style={{background: 'rgb(254,42,107)'}} onClick={() => {setEyeColor({r: 254, g: 42, b: 107})}}></button>
            <button className={styles.color} style={{background: 'rgb(0,196,220)'}} onClick={() => {setEyeColor({r: 0, g: 196, b: 220})}}></button>
            <button className={styles.color} style={{background: 'rgb(1,188,58)'}} onClick={() => {setEyeColor({r: 1, g: 188, b: 58})}}></button>
            <button className={styles.color} style={{background: 'rgb(158,102,183)'}} onClick={() => {setEyeColor({r: 158, g: 102, b: 183})}}></button>
        </div>
            <div className={styles.sliderSet}> 
              <label className={styles.sliderLabel}>{t('red')}</label>
              <input type="range" min="1" max="255" value={eyeColor.r} onChange={(e) => setEyeColor({...eyeColor, r: e.currentTarget.value})} />
            </div>
            <div className={styles.sliderSet}> 
              <label className={styles.sliderLabel}>{t('green')}</label>
              <input type="range" min="1" max="255" value={eyeColor.g} onChange={(e) => setEyeColor({...eyeColor, g: e.currentTarget.value})} />
            </div>
            <div className={styles.sliderSet}> 
              <label className={styles.sliderLabel}>{t('blue')}</label>  
              <input type="range" min="1" max="255" value={eyeColor.b} onChange={(e) => setEyeColor({...eyeColor, b: e.currentTarget.value})} />
            </div>
          </div>   
        </div>
      }
      {filter === 'MOUTH' &&
      <div className={styles.options}>
      <div className={cx(styles.flex, styles.cells)}>
        <button className={styles.cell} style={{backgroundColor: `rgb(${bgColor.r},${bgColor.g},${bgColor.b})`}} onClick={() => {setMouthImg(1)}}><svg viewBox="0 0 600 600" className={styles.svg}><Mouths.MemoMouth1 /></svg></button>
        <button className={styles.cell} style={{backgroundColor: `rgb(${bgColor.r},${bgColor.g},${bgColor.b})`}} onClick={() => {setMouthImg(2)}}><svg viewBox="0 0 600 600" className={styles.svg}><Mouths.MemoMouth2 /></svg></button>
        <button className={styles.cell} style={{backgroundColor: `rgb(${bgColor.r},${bgColor.g},${bgColor.b})`}} onClick={() => {setMouthImg(3)}}><svg viewBox="0 0 600 600" className={styles.svg}><Mouths.MemoMouth3 /></svg></button>
        <button className={styles.cell} style={{backgroundColor: `rgb(${bgColor.r},${bgColor.g},${bgColor.b})`}} onClick={() => {setMouthImg(4)}}><svg viewBox="0 0 600 600" className={styles.svg}><Mouths.MemoMouth4 /></svg></button>
        <button className={styles.cell} style={{backgroundColor: `rgb(${bgColor.r},${bgColor.g},${bgColor.b})`}} onClick={() => {setMouthImg(5)}}><svg viewBox="0 0 600 600" className={styles.svg}><Mouths.MemoMouth5 /></svg></button>
        <button className={styles.cell} style={{backgroundColor: `rgb(${bgColor.r},${bgColor.g},${bgColor.b})`}} onClick={() => {setMouthImg(6)}}><svg viewBox="0 0 600 600" className={styles.svg}><Mouths.MemoMouth6 /></svg></button>
        <button className={styles.cell} style={{backgroundColor: `rgb(${bgColor.r},${bgColor.g},${bgColor.b})`}} onClick={() => {setMouthImg(7)}}><svg viewBox="0 0 600 600" className={styles.svg}><Mouths.MemoMouth7 /></svg></button>
        <button className={styles.cell} style={{backgroundColor: `rgb(${bgColor.r},${bgColor.g},${bgColor.b})`}} onClick={() => {setMouthImg(8)}}><svg viewBox="0 0 600 600" className={styles.svg}><Mouths.MemoMouth8 /></svg></button>
      </div>
      </div>
      }
      <div className={styles.actions}>
        <button onClick={() => {generateRandom()}} className={cx(styles.action, styles.filter)}>{t('randomize')}</button>
        {loading ?
        <button className={cx(styles.action, styles.filter)} disabled>{t('saving')}</button> :
        <button className={cx(styles.action, styles.filter)} onClick={() => save()}>{t('button.save')}</button>
        }
      </div>
    </div>
  );
}

export default Edit;