import React from 'react';
import styles from './CardBought.module.scss';
import { CheckCircle } from 'react-feather';
import cx from 'classnames';
import ImageFadeIn from "react-image-fade-in";
import { useTranslation } from 'react-i18next';

const CardBought = ({ card, i, print = false }) => {

    const { t } = useTranslation(['rewardcards']);
    const roundFilledWithAuthor = card.pack === "Competition Winners 2022" && card.authorName && (i === 0 || i === 8 || i === 10);

    return (
        <div className={styles.card}>
            <div className={cx(styles.cardImg, `bg${i}`)}>
                <p className={styles.cardImgName}>{t(card.name)}</p>
                <div className={cx(styles.cardImgHolder, { [styles.cardImgHolderWithAuthor]: card.authorName }, { [styles.cardImgHolderRoundedFilled]: roundFilledWithAuthor })}>
                    <ImageFadeIn src={`${card.url}`} alt='' />
                    {card.authorName && <p className={cx(styles.cardImgHolderAuthor, { [styles.cardImgHolderAuthorWhite]: roundFilledWithAuthor })}>By {card.authorName}</p>}
                </div>
                <div className={styles.cardStats}>
                    <div className={styles.cardStatsItem}>
                        <p>{t('power')}</p>
                        <span>{card.power}</span>
                    </div>
                    <div className={styles.cardStatsItem}>
                        <p>{t('speed')}</p>
                        <span>{card.speed}</span>
                    </div>
                    <div className={styles.cardStatsItem}>
                        <p>{t('skill')}</p>
                        <span>{card.skill}</span>
                    </div>
                </div>
            </div>
            {!print &&
                <div className={styles.cardCheck}><CheckCircle /></div>
            }
        </div>
    )
}

export default CardBought;