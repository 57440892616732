// Queries and mutations for the Rewards module
import { gql } from 'apollo-boost';

const rewardCardQuery = `
id
name
chapterWinnerOnly
price
pack
skill
power
speed
url
authorName
`;


export const GET_REWARD_CARDS = gql`
query rewardCards($pack: String) {
    rewardCards(pack: $pack) {
       ${rewardCardQuery}
    }
}
`;

export const BUY_REWARD_CARD = gql`
mutation buyRewardCard($studentId: ID!, $rewardCardId: ID!) {
    buyRewardCard(studentId: $studentId, rewardCardId: $rewardCardId) {
        errors
        student {
            id
            availableGems
            studentRewardCards {
                id
                rewardCard {
                    id
                    chapterWinnerOnly
                    pack
                }
            }
      }
    }
}
`;


