import React, { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import PinField from "react-pin-field"
import { useEffectOnce } from 'react-use';
import { XCircle, User, Home } from 'react-feather';
import ImageFadeIn from 'react-image-fade-in';

import { Button } from '@axeedge/go-pupil-components';
import { useTranslation } from 'react-i18next';

import styles from '../Login.module.scss';
import avatar from '../../../images/boomer.svg';

const PinCode = ({ studentLogIn, formErrors, textErrors, loggingIn, pinRef, school, clearSchoolCode }) => {

    const { t } = useTranslation();

    useEffectOnce(() => {
        if (pinRef.current) {
            pinRef.current[0].focus();
        }
    })

    const schema = Yup.object().shape({
        pinCode: Yup.string()
            .required(t('please_enter_pin_code'))
    });

    const { register, handleSubmit, errors, setValue } = useForm({
        validationSchema: schema,
        mode: "onBlur"
    });

    const onSubmit = ({ pinCode }) => {
        studentLogIn({
            variables: {
                code: pinCode,
                schoolId: school.id
            }
        });
    }

    const renderInfoTexts = () => {
        return textErrors ? (
            <Fragment>
                <p>{t('need_help_login')}</p>
                <p>{t('check_pin_school_code')}</p>
                <p>{t('ask_teacher_for_help')}</p>
            </Fragment>
        ) : <p className="u-mb-0">{t('ask_teacher_for_login_sheet')}</p>
    }

    return (
        <Fragment>
            <div className={styles.infoPanelMobile}>
                <img className={styles.infoPanelMobileAvatar} src={avatar} alt="" />
                <div className={styles.infoPanelMobileMain}>
                    {renderInfoTexts()}
                </div>
            </div>
            <div className={styles.loginPanelContent}>
                <div className={styles.loginPanelMain}>
                    <div className={styles.schoolInfo}>
                        {school.avatarUrl && <ImageFadeIn className={styles.schoolInfoLogo} src={school.avatarUrl} />}
                        <div>
                            <p className={styles.schoolInfoCode}><Home className='u-m-right-1' size="20" /> {school.schoolCode}</p>
                            <p className="u-mb-0">{school.name}</p>
                            <button className={styles.backLink} onClick={clearSchoolCode}><XCircle size="16" /> {t('change')}</button>
                        </div>

                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <h2 className='u-text-center heavy u-m-base-2'><User className='u-m-right-1' />{t('pin_code')}</h2>
                        {formErrors.length !== 0 && <p className={styles.loginError}>{formErrors.map(error => t(error))} </p>}
                        <input
                            name="pinCode"
                            ref={register}
                            type="hidden"
                        />
                        <div className={styles.pinWrapper}>
                            <PinField
                                className={styles.pinWrapperField}
                                ref={pinRef}
                                validate={/^[0-9]$/}
                                onChange={(value) => { value.length === 5 ? setValue('pinCode', value) : setValue('pinCode', '') }}
                                format={k => k.toUpperCase()}
                            />
                        </div>
                        {errors.pinCode && <p className="basic-form__hint">{errors.pinCode.message}</p>}
                        <div className="u-display-flex u-align-center">
                            <Button type="submit" primary fullWidth>{loggingIn ? t('logging_in') : t('login')}</Button>
                        </div>
                    </form>
                </div>
                <div className={styles.loginPanelSide}>
                    <img className={styles.loginPanelSideAvatar} src={avatar} alt="" />
                    <div className={styles.infoPanel}>
                        {renderInfoTexts()}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default PinCode;
