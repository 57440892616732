import React, { useState, useEffect } from 'react';
import CardLocked from './CardLocked';
import CardBought from './CardBought';

import styles from '../Rewards.module.scss';
import cx from  'classnames';

const CardHolder = ({ selectedPack, card, userCard, i, canBuyWinner, print = false }) => {

    const [flipAnimation, setFlipAnimation] = useState(false);
    const [flipCard, setFlipCard] = useState();

    useEffect(() => {
        if (flipAnimation) {
            setTimeout(() => {
                setFlipCard(true);
            }, 100)
        }
    }, [flipAnimation, setFlipCard])
    
    return(
        <div className={styles.rewardsItem}>
            {
                userCard ? (
                        <div className={cx(styles.flipCard, {[styles.flipCardFlipped]:flipCard})}>
                            <div className={styles.flipCardInner}>
                                {flipCard && (
                                    <div className={styles.flipCardFront}>
                                       <CardLocked setFlipAnimation={false} selectedPack={selectedPack} card={card} />
                                   </div> 
                                )}
                                <div className={cx({[styles.flipCardBack]: flipAnimation})}>
                                    <CardBought i={i} card={card} />
                                </div>
                            </div>
                        </div>
                    
                ) : <CardLocked canBuyWinner={canBuyWinner} setFlipAnimation={setFlipAnimation} selectedPack={selectedPack} card={card} />
            }
        </div>
    )
}

export default CardHolder;