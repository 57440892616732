import React, { Fragment, useContext, useRef, useState, useEffect } from 'react';
import { useApolloClient, useMutation, useLazyQuery, useQuery } from '@apollo/react-hooks';
import ls from 'local-storage';
import { Loader } from '@axeedge/go-pupil-components';
import { CheckCircle, ArrowLeft } from 'react-feather';
import { Button } from '@axeedge/go-pupil-components';
import styles from './Login.module.scss';
import { GET_WONDE_URL } from "./services/graphql";

import SchoolCode from './components/SchoolCode';
import PinCode from './components/PinCode';
import { AuthContext } from '../../services/auth/AuthProvider';
import { LOGIN, GET_SCHOOL_QUERY } from './services/graphql';
import { getAppTokenName, getUrl, APPS } from '@axeedge/go-shared-utils';
import { APP_NAME, APP_NAME_US } from '../../services/constants';
import { Menu } from 'react-feather';
import logoGoApps from '../../images/boomer.svg';
import usa from '../../images/usa.png';
import uk from '../../images/uk.png';
import row from '../../images/row.png';
import { useEffectOnce, useLocation } from 'react-use';
import { useTranslation, Trans } from 'react-i18next';
import cx from 'classnames';
import AddToHomeScreen from './components/AddToHomeScreen'
import { parse } from 'query-string';
import { FormErrors } from '@axeedge/go-teacher-components';

const Login = () => {

    const location = useLocation();

    const { t } = useTranslation();
    const [school, setSchool] = useState(null);
    const [ready, setReady] = useState(false);

    const [formErrors, setFormErrors] = useState([]);
    const [textErrors, setTextErrors] = useState(false);
    const [mobileVisible, setMobileVisible] = useState(false);

    const auth = useContext(AuthContext);
    const client = useApolloClient();
    const pinRef = useRef(null);
    const codeRef = useRef(null);

    const wondeLoginError = parse(location.search).wondeError;
    const [schoolError, setSchoolError] = useState([]);

    const { data } = useQuery(GET_WONDE_URL, {
        fetchPolicy: 'network-only',
        variables: {
            from: 'Student'
        }
    });

    const [showCountrySelect, setShowCountrySelect] = useState(false)
    const [selectedCountry, setSelectedCountry] = useState(ls('country'))
    const [showDevice, setShowDevice] = useState(false)

    const [getSchool, { loading: gettingSchool }] = useLazyQuery(GET_SCHOOL_QUERY, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            setReady(true);
            if (data && data.schoolByCode) {
                setSchool(data.schoolByCode);
                ls('go_student_school_code', data.schoolByCode.schoolCode);
                setSchoolError([]);
            }
            else {
                setSchoolError([t('no_school_with_code_found')]);
            }
        }
    });

    useEffectOnce(() => {
        if (ls('go_student_school_code')) {
            getSchool({ variables: { schoolCode: ls('go_student_school_code') } });
        } else {
            setReady(true);
        }
    });

    const [studentLogIn, { loading: loggingIn }] = useMutation(LOGIN, {
        awaitRefetchQueries: true,
        onCompleted: (data) => {
            if (data.studentLogIn.errors && data.studentLogIn.errors.length !== 0) {
                setFormErrors(data.studentLogIn.errors);
                setTextErrors(true);
                if (pinRef.current) {
                    pinRef.current[0].focus();
                }
                return;
            }
            if (data.studentLogIn.student && data.studentLogIn.student.id && data.studentLogIn.token) {
                ls(getAppTokenName(ls('country') === 'US' ? APP_NAME_US : APP_NAME), data.studentLogIn.token);
                ls('go_student_user', JSON.stringify(data.studentLogIn.student));
                auth.setCurrentUser(data.studentLogIn.student);
                client.writeData({
                    data: {
                        loggedIn: true,
                        forcedLogout: false
                    }
                });
            }
        }
    });

    const ref = useRef(null);
    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setMobileVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () =>
            document.removeEventListener('click', handleClickOutside, true);
    }, []);

    const clearSchool = () => {
        setSchool(null);
        ls.remove('go_student_school_code');
    }


    return (
        <Fragment>

            <div className={styles.loginHeader}>
                <div className={styles.loginHeaderLogo}>
                    <img className={styles.loginHeaderLogo} src={logoGoApps} alt="Go Apps Logo" />
                </div>
                <div className={styles.headerFlag} onClick={() => setShowCountrySelect(!showCountrySelect)}>
                    <img className={styles.headerFlagImg} src={ls('country') === 'US' ? usa : ls('country') === 'GB' ? uk : row} />
                </div>
            </div>

            <div className={styles.loginWrapper}>
                <div className={styles.loginWrapperMain}>
                    <div className={styles.login}>
                        <div className={styles.loginPanel}>
                            {showCountrySelect ?
                                <div className={styles.selectContainer}>
                                    <span className={styles.close} onClick={() => setShowCountrySelect(false)}>
                                        <ArrowLeft />
                                    </span>
                                    <h1>Change Country</h1>
                                    <p className={styles.countrySub}>Please ask your teacher or parent for help.</p>
                                    <ul className={styles.countryList}>
                                        <li onClick={() => setSelectedCountry('US')} className={cx(styles.option, selectedCountry === 'US' && styles.optionSelected)}><img className={styles.countrySelectImgAlt} src={usa} /><p className={styles.countrySelectPara}>USA</p></li>
                                        <li onClick={() => setSelectedCountry('GB')} className={cx(styles.option, selectedCountry === 'GB' && styles.optionSelected)}><img className={styles.countrySelectImgAlt} src={uk} /><p className={styles.countrySelectPara}>United Kingdom</p></li>
                                        <li onClick={() => setSelectedCountry('OTHER')} className={cx(styles.option, selectedCountry === 'OTHER' && styles.optionSelected)}><img className={styles.countrySelectImgAlt} src={row} /><p className={styles.countrySelectPara}>Other Country</p></li>
                                    </ul>
                                    <Button onClick={() => { ls('country', selectedCountry); clearSchool(); window.location.replace(window.location.pathname); }}>Set My Country</Button>
                                </div> :
                                <>
                                    {
                                        showDevice ?
                                            <div className={styles.selectContainer}>
                                                <span className={styles.close} onClick={() => setShowDevice(false)}>
                                                    <ArrowLeft />
                                                </span>
                                                <AddToHomeScreen />
                                            </div>
                                            :
                                            <>
                                                <div ref={ref} style={{ position: 'relative' }}>
                                                    <div className={cx(styles.loginMobileToggle)} onClick={() => setMobileVisible(!mobileVisible)}><Trans i18nKey='im_a_pupil'> I'm a <span>Pupil</span></Trans> <Menu /></div>
                                                    <ul className={cx(styles.loginMobile, { [styles.loginMobileVisible]: mobileVisible })}>
                                                        <li className={styles.loginMobileLink}><a href={`${getUrl(APPS.go_parents_desktop)}`}><Trans i18nKey='im_a_parent'> I'm a <span>Parent</span></Trans></a></li>
                                                        <li className={styles.loginMobileLink}><a href={`${getUrl(APPS.go_portal_teacher)}`}><Trans i18nKey='im_a_teacher'> I'm a <span>Teacher</span></Trans></a></li>
                                                    </ul>
                                                    <ul className={styles.loginOpts}>
                                                        <li className={cx(styles.loginOptsLink, styles.loginOptsLinkActive)}>I'm a <span>{ls('country') === 'US' ? 'Student' : 'Pupil'}</span></li>
                                                        {ls('country') !== 'US' && <li className={styles.loginOptsLink}><a href={`${getUrl(APPS.go_parents_desktop)}`}><Trans i18nKey='im_a_parent'> I'm a <span>Parent</span></Trans></a></li>}
                                                        <li className={styles.loginOptsLink}><a href={`${getUrl(APPS.go_portal_teacher)}/?region=${ls('country')}`}><Trans i18nKey='im_a_teacher'> I'm a <span>Teacher</span></Trans></a></li>
                                                    </ul>
                                                </div>

                                                {
                                                    ready ? (
                                                        <Fragment>
                                                            {
                                                                !school ? (
                                                                    <SchoolCode gettingSchool={gettingSchool} getSchool={getSchool} schoolError={schoolError} codeRef={codeRef} setShowDevice={setShowDevice} />
                                                                ) : (
                                                                    <PinCode studentLogIn={studentLogIn} pinRef={pinRef} loggingIn={loggingIn} formErrors={formErrors} textErrors={textErrors} clearSchoolCode={clearSchool} school={school} />
                                                                )
                                                            }
                                                            {/* {data && data.wondeLoginUrl &&
                                                                <div className={styles.wondeWrapper}>
                                                                    {wondeLoginError && <FormErrors errors={[wondeLoginError]} />}
                                                                    <a className={styles.wondeBtn} href={`${data.wondeLoginUrl}`}>Login with Wonde</a>
                                                                </div>
                                                            } */}
                                                        </Fragment>
                                                    ) : <div className={styles.loginPanel}>
                                                        <Loader />
                                                    </div>
                                                }
                                            </>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
                {/* <div className={styles.loginWrapperSide}>
                    <AddToHomeScreen />
                </div> */}
            </div>
        </Fragment>
    )
}

export default Login;
