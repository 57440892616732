import React, { useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useEffectOnce } from 'react-use';
import { navigate } from '@reach/router';
import ImageFadeIn from "react-image-fade-in";
import { useApolloClient } from '@apollo/react-hooks';
import ls from 'local-storage';

import { getAppTokenName } from '@axeedge/go-shared-utils';
import { AuthContext } from '../../services/auth/AuthProvider';
import { LOGOUT_MUTATION } from './services/graphql';
import { APP_NAME, APP_NAME_US } from '../../services/constants';
import { useTranslation } from 'react-i18next';

import logo from '../../images/go-apps-icon-tm-reversed.svg';

const Logout = () => {
    const { t } = useTranslation();

    const client = useApolloClient();
    const { setCurrentUser } = useContext(AuthContext);

    const [logOut] = useMutation(LOGOUT_MUTATION, {
        onCompleted: ({ logOut }) => {
            if (logOut.errors && logOut.errors.length !== 0) {
                alert(logOut.errors[0]);
            }
            if (logOut.loggedOut) {
                ls.remove('go_student_user');
                ls.remove(getAppTokenName(ls('country') === 'US' ? APP_NAME_US : APP_NAME));
                
                setCurrentUser(null);
                client.clearStore();
                navigate('/');
            }
        }
    })

    useEffectOnce(() => {
        logOut({
            variables: {
                token: ls(getAppTokenName(ls('country') === 'US' ? APP_NAME_US : APP_NAME))
            }
        });
    })

    return (
        <div className='auth-page__container'>
            <div className='auth-page__panel'>
                <ImageFadeIn className='auth-page__logo' src={logo} />
                <h1 className='auth-page__title'>{t('logging_out_of_goapps')}</h1>
            </div>
        </div>
    );
}

export default Logout;
