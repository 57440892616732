import React, { useContext, Fragment, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { ActionCableWrapper, NotifCableConsumer } from './ActionCableWrapper';
import { Loader, RewardNotification } from '@axeedge/go-pupil-components';

import { CURRENT_STUDENT_QUERY } from '../services/graphql';
import { AuthContext } from '../../../services/auth/AuthProvider';

import Header from '../../../components/Header';
import Nav from '../../../components/Nav';

const LoggedInRouter = ({ children }) => {
    const [reward, setReward] = useState(null);
    const { currentUser, setCurrentUser } = useContext(AuthContext);

    const { error, loading } = useQuery(CURRENT_STUDENT_QUERY, {
        fetchPolicy: 'network-only',
        onCompleted: ({ currentStudent }) => {
            setCurrentUser(currentStudent);
        },
        pollInterval: 60000
    });

    const onReward = reward => {
        setReward(reward);
        setCurrentUser({
            ...currentUser,
            availableGems: reward.total_available_gems
        });
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (currentUser.school) {
        return (
            <Fragment>
                <ActionCableWrapper>
                    <NotifCableConsumer id={currentUser.id} onLogout={() => console.log('logging out...')} onReward={onReward} />
                    <div className='app__layout'>
                        <Nav />
                        <div className='app__content'>
                            {children}
                        </div>
                        {reward && <RewardNotification reward={reward} clearReward={() => setReward(null)} />}
                    </div>
                </ActionCableWrapper>
            </Fragment>

        );
    }

    return null
}

export default LoggedInRouter;
