import React, { useState, useContext, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { AuthContext } from '../../services/auth/AuthProvider';

import { Loader, Button } from '@axeedge/go-pupil-components';
import { GET_REWARD_CARDS } from './services/graphql';
import { REWARD_CARD_PACKS } from '../../services/constants';
import CardHolder from './components/CardHolder';
import PrintCard from './components/PrintCard';
import { useLocation } from '@reach/router';
import gem from '../Dashboard/images/gem.svg';
import styles from './Rewards.module.scss';
import { useTranslation } from 'react-i18next';
import { parse } from "query-string";

const Rewards = () => {
    const location = useLocation();
    const { t } = useTranslation(['rewardcards', 'translation']);

    const { currentUser } = useContext(AuthContext);

    const [selectedPack, setSelectedPack] = useState(REWARD_CARD_PACKS[0]);
    const [packCards, setPackCards] = useState();

    const [getCards, { error, loading }] = useLazyQuery(GET_REWARD_CARDS, {
        onCompleted: data => {
            setPackCards(data.rewardCards);
        }
    });

    useEffect(() => {
        if (selectedPack && !parse(location.search).showPrint) {
            getCards({
                variables: {
                    pack: selectedPack.pack
                }
            })
        }
    }, [selectedPack, getCards])

    useEffect(() => {
        if (parse(location.search).pack) {
            getCards({
                variables: {
                    pack: parse(location.search).pack
                }
            })
        }
    }, [location])


    if (parse(location.search).showPrint) {
        return <> {packCards &&
            <div className={styles.printContainer}>
                <div className={styles.printHeader}>
                    <div className={styles.printHeaderInner}>
                        <p className="u-m-base-2">If you use the Chrome browser please make sure the <b>Background graphics</b> setting is checked under <b>More settings</b> when printing if you wish to print the cards with their correct background colours displayed. You may need your Teacher or Parent to help with this.</p>
                        <Button onClick={() => window.print()}>Print</Button>
                    </div>
                </div>
                <div className={styles.rewardsWrapper}>
                    {loading ? <Loader /> :
                        !!packCards.length && packCards.map((card, i) => {
                            return (
                                <PrintCard
                                    key={`'card-${card.id}`}
                                    userCard={currentUser.studentRewardCards.find(c => c.rewardCard.id === card.id)}
                                    card={card}
                                    i={i}
                                    selectedPack={selectedPack}
                                />
                            )
                        })
                    }
                </div>
            </div>}
        </>
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (packCards) {

        const canBuyWinner = currentUser.studentRewardCards.filter(c => c.rewardCard.pack === selectedPack.pack).length === packCards.filter(c => !c.chapterWinnerOnly).length;

        return (
            <div className={styles.rewards}>
                <div className={styles.rewardsHeader}>
                    <h1>{t('reward_cards')}</h1>
                    <h2>{t('buy_cards_with_gems')}</h2>
                    <div className={styles.gemPanel}>
                        <img src={gem} className={styles.gem} alt={t('translation:gem')} />
                        <p>{currentUser.availableGems} {currentUser.availableGems === 1 ? t('translation:gem') : t('translation:gems')}</p>
                    </div>
                </div>
                <div className='card'>
                    <div className='card-body'>
                        <ul className={styles.rewardsNav}>
                            {
                                REWARD_CARD_PACKS.map(categ => (
                                    <li key={`categ-${categ.key}`}>
                                        <Button
                                            outline={selectedPack.pack !== categ.pack}
                                            onClick={() => setSelectedPack(REWARD_CARD_PACKS.find(c => c.pack === categ.pack))}
                                        >
                                            {t(categ.key)}
                                        </Button>
                                    </li>
                                ))
                            }
                        </ul>
                        <div className={styles.rewardsWrapper}>
                            {loading ? <Loader /> :
                                !!packCards.length && packCards.map((card, i) => {
                                    return (
                                        <CardHolder
                                            key={`'card-${card.id}`}
                                            userCard={currentUser.studentRewardCards.find(c => c.rewardCard.id === card.id)}
                                            card={card}
                                            i={i}
                                            selectedPack={selectedPack}
                                            canBuyWinner={canBuyWinner}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className={styles.printBtn}>
                        <a href={`/rewards?showPrint=true&pack=${selectedPack.pack}`} target="_blank" className="button">Printable Cards</a>
                    </div>
                </div>
            </div>
        )
    }



    return null
}

export default Rewards;