// Queries and mutations specifically for the Avatar Scene
import { gql } from 'apollo-boost';

export const GET_AVATAR_QUERY = gql`
query myAvatar {
    currentStudent {
        id
        avatarJson
    }
}
`

export const SAVE_AVATAR = gql`
mutation saveAvatar($avatarJson: String!) {
    studentSaveAvatar(avatarJson: $avatarJson) {
        student {
            id
        }
        errors
    }
}
`
