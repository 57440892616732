import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import ls from 'local-storage';

import AutoSaveBoomer from './AutoSaveBoomer';
import loader from '../../../images/loader-boomer.png';

import { 
  BOOMER_BODY_HEIGHT,
  BOOMER_FALLBACK_DATA,
  BOOMER_IMG_PATH, 
  BOOMER_ITEM_ORDER,
  BOOMER_ITEMS_BASE,
  BOOMER_ITEMS_TOP,
  BOOMER_MAX_HEIGHT,
  BOOMER_MAX_WIDTH,
} from '../../../services/utils/boomer';

const DrawBoomer = ({ badge, boomerData, forceSave, mutation, onBoomerLoaded, setBoomerImage, allItems }) => {
  //return null;

  const [placedBoomer, setPlacedBoomer] = useState({
    imgData: loader,
    drawn: false
  });
  const [loadedItems, setLoadedItems] = useState([])
  
  const hiddenCanvas = useRef(null);
  const boomerImage = useRef();
  const boomerDataRef = useRef();
  
  let useFallback = !(boomerData && boomerData.body);
  let hiddenCvs;
  let sortedItems = [];
  const yPadHidden = ((BOOMER_BODY_HEIGHT - BOOMER_ITEMS_BASE) - BOOMER_ITEMS_TOP) / 2
  
  useEffect(() => {
    // if nothing has happended, set up the canvas
    if (hiddenCanvas && !hiddenCvs) {
      hiddenCvs = hiddenCanvas.current.getContext('2d');
    }
    // we haven't loaded anything yet or if data has changed
    if ((loadedItems.length === 0) || (!_.isEqual(boomerData, boomerDataRef.current))) {
      if (placedBoomer.drawn) {
        setPlacedBoomer(prevState => {
          return { ...prevState, drawn: false }
        });
      } else {
        useFallback = !(boomerData && boomerData.body);
        boomerDataRef.current = boomerData;
        sortedItems = boomerData && boomerData.body ? _.sortBy(_.filter(boomerData.items, (item) => { return item !== null}), [function(item) { return _.indexOf(BOOMER_ITEM_ORDER, item.type) }]) : [];
        loadBody();
      }
    } else if (!placedBoomer.drawn) {
      // just drawer the damn thing
      placeBoomer();
    }
    
    if (placedBoomer.drawn && onBoomerLoaded && boomerImage.current) {      
      onBoomerLoaded(true);
    }
  });

  const loadBody = () => {
    const bData = useFallback ? BOOMER_FALLBACK_DATA : boomerData;
    const items = [];
    ls('lastBoomerData', JSON.stringify(bData));
    const body = new Image();
    body.crossOrigin = "anonymous"
    body.src = `/img/boomer/avatar-items/${Number(allItems.filter(i => Number(i.id) === Number(bData.body.body_id))[0].xmlId)}.png`;
    body.addEventListener("load", () => {
      items.push({ img: body, iData: bData.body });
      loadItems(items);
    });
  }

  const loadItems = items => {
    if (sortedItems.length !== 0) {
      loadItem(0, items);
    } else {
      setLoadedItems(items);
    }
  }

  const loadItem = (ind, items) => {
    const iData = sortedItems[ind];
    const item = new Image();
    item.crossOrigin = "anonymous";
    item.src = `${BOOMER_IMG_PATH}${Number(iData.xmlId)}${Number(iData.tile) !== undefined ? '-' + Number(iData.tile) : ''}.png`;
    item.addEventListener("load", () => {
      items = [...items, { img: item, iData: iData }]
      if (ind < sortedItems.length - 1) {
        loadItem(ind + 1, items);
      } else {
        setLoadedItems(items);
      }
    });
  }

  const placeBoomer = () => {
    let hiddenBodyY;
    if (hiddenCvs) {
      hiddenCvs.clearRect(0, 0, hiddenCanvas.current.width, hiddenCanvas.current.height);

      _.forEach(loadedItems, (item, i) => {
        const xOffset = Number(item.iData.xOffset) || 0;
        const yPos = Number(item.iData.yPos) || 0;
        if (i === 0) {
          hiddenBodyY = (hiddenCanvas.current.height / 2) - (item.img.height / 2) + yPadHidden;
        }
        const middleHidden = (hiddenCanvas.current.width / 2) - (item.img.width / 2);
        hiddenCvs.drawImage(
          item.img,
          middleHidden + xOffset,
          hiddenBodyY + yPos,
          item.img.width,
          item.img.height
        );
      });

      const imgData = hiddenCanvas.current.toDataURL();
      if (setBoomerImage) {
        setBoomerImage(imgData);
      }

      setPlacedBoomer({
        imgData,
        drawn: true
      });
    }
  }

  return (
    <div className={`boomer-holder ${mutation ? 'boomer-holder--' + mutation : ''}`}>
      {
        placedBoomer.imgData ? 
          (
            forceSave ? 
            <AutoSaveBoomer imgData={placedBoomer.imgData} /> : 
            <img alt="Your Boomer" className="boomer-holder__image" src={placedBoomer.imgData} ref={boomerImage} />
          )
        :
        <img alt="Your Boomer" className="boomer-holder__loader" src={loader} />
      }
      {badge && placedBoomer.imgData && <img src={badge} alt="badge" className="boomer-holder__badge"/>}
      <canvas
        className="boomer-holder__hidden-canvas"
        height={`${BOOMER_MAX_HEIGHT}`}
        ref={hiddenCanvas}
        width={`${BOOMER_MAX_WIDTH}`}
      />
    </div>
  );
}

export default DrawBoomer;
