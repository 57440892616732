import React, { useEffect, useContext, useState } from 'react'
import ls from 'local-storage';
import { useApolloClient, useLazyQuery } from '@apollo/react-hooks';
import { getAppTokenName } from '@axeedge/go-shared-utils';
import { APP_NAME, APP_NAME_US } from '../../services/constants';
import { AuthContext } from '../../services/auth/AuthProvider';
import { LOGIN } from './services/graphql'
import {navigate} from '@reach/router'
const Impersonate = ({token}) => {

    const auth = useContext(AuthContext);
    const client = useApolloClient();
    const [errors, setErrors] = useState(false);

    const [getStudent, { loading }] = useLazyQuery(LOGIN, {
        onCompleted: (data) => {
            if (data.currentStudent) {
                ls('go_student_user', JSON.stringify(data.currentStudent));
                auth.setCurrentUser(data.currentStudent);
                client.writeData({
                    data: {
                        loggedIn: true,
                        forcedLogout: false
                    }
                });
                navigate('/');
            } else {
                setErrors(true)
            }
        },
        fetchPolicy: 'network-only'
    });

    useEffect(() => {
        ls.clear()
        auth.setCurrentUser(null)
        ls(getAppTokenName(ls('country') === 'US' ? APP_NAME_US : APP_NAME), token)
        getStudent()
    }, [])

    return <p>Logging In</p>
}

export default Impersonate