import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import Container from './components/Container';
import { BOOMER_STORE_QUERY, ALL_ITEMS_QUERY } from './services/graphql';

const Store = () => {
    const { data, error, loading } = useQuery(BOOMER_STORE_QUERY);

    if (error) {
        return <p>{error.message}</p>
    }
    if (loading) {
        return <p>Loading</p>
    }
    if (data && data.currentStudent) {
        return <Container data={data.currentStudent} />
    }
    return null;
}

export default Store;