import React, { useState } from "react";
import phoneScreen from '../../../images/phone-screen.png';
import ImageFadeIn from "react-image-fade-in";
import Tab from '../../../components/Tab';
import Tabs from '../../../components/Tabs';

const AddToHomeScreen = () => {

    const [showDevice, setShowDevice] = useState('ios');

    return (
        <div className="u-text-left u-p-3">
            <div className='u-display-flex u-align-center u-m-base-2'>
                <ImageFadeIn src={phoneScreen} alt='' width={70} className='u-m-right-2' />
                <div>
                    <h4 className="heavy">Looking for the app?</h4>
                    <p>Save this site as an icon on your device by:</p>
                </div>
            </div>

            <Tabs className='u-m-base-2'>
                <Tab onClick={() => setShowDevice('ios')} selected={showDevice === 'ios'}>iPad and iPhone</Tab>
                <Tab onClick={() => setShowDevice('android')} selected={showDevice === 'android'}>Android</Tab>
            </Tabs>

            {showDevice === 'ios' ? (
                <>
                    <p className="u-m-base-1">Open the Safari web browser on your iPhone and visit the site you want to add to your device's home screen.</p>
                    <p className="u-m-base-1">At the bottom of the screen, tap on the Share button.</p>
                    <p className="u-m-base-1">On the Share menu, scroll down and tap on the Add to Home Screen option.</p>
                    <p>Now, you need to type in the website's name and tap the add button.</p>
                </>
            ) : (
                <>
                    <p className="u-m-base-1">Open the Google Chrome app on your Android phone or tablet and visit the site you want to add to your device's home screen.</p>
                    <p className="u-m-base-1">Tap the three vertical dots in the top right corner. From the drop-down, select Add to Home Screen.</p>
                    <p className="u-m-base-1">Enter the website's name (or another description) that will appear on your Home Screen.</p>
                    <p>Tap the Add button in the bottom right corner of the prompt.</p>
                </>
            )}


        </div>
    )
}

export default AddToHomeScreen;