// Queries and mutations for the Home module
import { gql } from 'apollo-boost';

export const LOGIN = gql`
mutation studentLogIn($code: String!, $schoolId: ID!) {
    studentLogIn(code: $code, schoolId: $schoolId) {
        student {
            id
            active
            avatarJson
            firstName
            name
            loginCode
            expiredToken
            updatedAt
            classes {
                id
                name
            }
        }
        token
        errors
    }
}
`;

export const GET_SCHOOL_QUERY = gql`
query schoolByCode($schoolCode: ID!) {
    schoolByCode(schoolCode: $schoolCode) {
        id
        name
        schoolCode
        city
        address
        avatarUrl
    }
}
`;

export const GET_WONDE_URL = gql`
query wondeLoginUrl($from: String!) {
    wondeLoginUrl(from: $from)
}
`;