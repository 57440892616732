import React, { Fragment } from 'react';
import { useForm } from 'react-hook-form';

import * as Yup from 'yup';
import PinField from "react-pin-field"
import { Button } from '@axeedge/go-pupil-components';
import styles from '../Login.module.scss';
import { useTranslation } from 'react-i18next';
import avatar from '../../../images/boomer.svg';

const SchoolCode = ({ codeRef, getSchool, schoolError, gettingSchool, setShowDevice }) => {

    const { t } = useTranslation();

    const schema = Yup.object().shape({
        schoolCode: Yup.string()
            .required(t('please_enter_school_code'))
    });

    const { register, handleSubmit, errors, setValue } = useForm({
        validationSchema: schema,
        mode: "onBlur"
    });

    const onSubmit = ({ schoolCode }) => {
        getSchool({ variables: { schoolCode } })
    }

    return (
        <Fragment>
            <div className={styles.infoPanelMobile}>
                <img className={styles.infoPanelMobileAvatar} src={avatar} alt="" />
                <div className={styles.infoPanelMobileMain}>
                    <p className="u-mb-0">{t('ask_teacher_for_login_sheet')}</p>
                </div>
            </div>
            <div className={styles.loginPanelContent}>

                <div className={styles.loginPanelMain}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <h2 className='u-text-center heavy u-m-base-2'>{t('school_code')}</h2>
                        {schoolError.length !== 0 && <p className={styles.loginError}>{schoolError}</p>}
                        {schoolError.length !== 0 && schoolError[0].toLowerCase().replace(/\s/g, '').includes('noschool') && <p className="u-m-base-2 u-bold">Make sure you have the right country set.</p>}
                        <input
                            name="schoolCode"
                            ref={register}
                            type="hidden"
                        />
                        <div className={styles.pinWrapper}>
                            <PinField
                                className={styles.pinWrapperField}
                                ref={codeRef}
                                validate={/^[0-9]$/}
                                onChange={(value) => { value.length === 5 ? setValue('schoolCode', value) : setValue('schoolCode', '') }}
                                format={k => k.toUpperCase()}
                            />
                        </div>
                        {errors.schoolCode && <p className="basic-form__hint">{errors.schoolCode.message}</p>}
                        <div className="u-display-flex u-align-center">
                            <Button type="submit" primary fullWidth>{gettingSchool ? t('loading') : t('button.next')}</Button>
                        </div>
                        <div className={styles.addToDevice} onClick={() => setShowDevice(true)}><span className={styles.addToDeviceText}>Add to my device</span></div>
                    </form>
                </div>
                <div className={styles.loginPanelSide}>
                    <img className={styles.loginPanelSideAvatar} src={avatar} alt="" />
                    <div className={styles.infoPanel}>
                        <p className="u-mb-0">{t('ask_teacher_for_login_sheet')}</p>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default SchoolCode;
