import React, { useEffect, useContext, useRef, useState, Fragment } from 'react';
import { useParams } from '@reach/router';
import { useApolloClient, useMutation, useLazyQuery } from '@apollo/react-hooks';
import ls from 'local-storage';
import { parse } from "query-string";
import logoGoApps from '../../../images/boomer.svg';
import { CheckCircle, ArrowLeft } from 'react-feather';

import { getAppTokenName, getUrl } from '@axeedge/go-shared-utils';

import styles from '../Login.module.scss';
import SchoolCode from '../components/SchoolCode';
import AddToHomeScreen from '../components/AddToHomeScreen'
import PinCode from '../components/PinCode';
import { AuthContext } from '../../../services/auth/AuthProvider';
import { LOGIN, GET_SCHOOL_QUERY } from '../services/graphql';
import { APP_NAME, APP_NAME_US } from '../../../services/constants';
import { useTranslation } from 'react-i18next';

import { useEffectOnce } from 'react-use';

const RedirectAfterLogin = ({ location }) => {

    const { t } = useTranslation();
    const [token, setToken] = useState(ls(getAppTokenName(ls('country') === 'US' ? APP_NAME_US : APP_NAME)));
    const params = useParams();
    const [school, setSchool] = useState(null);
    const [formErrors, setFormErrors] = useState([]);
    const auth = useContext(AuthContext);
    const client = useApolloClient();
    const pinRef = useRef(null);
    const [showDevice, setShowDevice] = useState(false)

    const [schoolError, setSchoolError] = useState([]);


    useEffect(() => {
        if (token) {
            window.location.href = `${getUrl(Number(params.app))}${location.search.length ? (parse(location.search).targetUrl !== undefined ? parse(location.search).targetUrl : '') : ''}?token=${token}`;
        }

    }, [token, params.app, location.search]);


    const [getSchool, { loading: gettingSchool }] = useLazyQuery(GET_SCHOOL_QUERY, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data && data.schoolByCode) {
                setSchool(data.schoolByCode);
                ls('go_student_school_code', data.schoolByCode.schoolCode);
                setSchoolError([]);
            }
            else {
                setSchoolError([t('no_school_with_code_found')]);
            }
        }
    });
    useEffectOnce(() => {
        if (ls('go_student_school_code')) {
            getSchool({ variables: { schoolCode: ls('go_student_school_code') } });
        }
    });

    const [studentLogIn, { loading: loggingIn }] = useMutation(LOGIN, {
        awaitRefetchQueries: true,
        onCompleted: (data) => {
            if (data.studentLogIn.errors && data.studentLogIn.errors.length !== 0) {
                setFormErrors(data.studentLogIn.errors);
                if (pinRef.current) {
                    pinRef.current[0].focus();
                }
                return;
            }
            if (data.studentLogIn.student && data.studentLogIn.student.id && data.studentLogIn.token) {
                setToken(data.studentLogIn.token);
                ls(getAppTokenName(ls('country') === 'US' ? APP_NAME_US : APP_NAME), data.studentLogIn.token);
                ls('go_student_user', JSON.stringify(data.studentLogIn.student));
                auth.setCurrentUser(data.studentLogIn.student);
                client.writeData({
                    data: {
                        loggedIn: true,
                        forcedLogout: false
                    }
                });
            }
        }
    });

    const clearSchool = () => {
        setSchool(null);
        ls.remove('go_student_school_code');
    }

    return (
        <Fragment>
            {!token && (
                <div className={styles.loginHeader}>
                    <div className={styles.loginHeaderLogo}>
                        <img className={styles.loginHeaderLogo} src={logoGoApps} alt="Go Apps Logo" />
                    </div>
                </div>
            )}

            <div className={styles.loginWrapper}>
                <div className={styles.login}>
                    <div className={styles.loginPanel}>

                        {

                            showDevice ? 
                                <div className={styles.selectContainer}>
                                    <span className={styles.close} onClick={() => setShowDevice(false)}>
                                        <ArrowLeft />
                                    </span>
                                    <AddToHomeScreen />
                                </div>
                            :
                            token ? (
                                <h2 className='u-p-top-4 u-p-base-3 u-text-center'>{t('we_are_logging_you_in')}</h2>
                            ) : (
                                <Fragment>

                                    {
                                        !school ? (
                                            <SchoolCode gettingSchool={gettingSchool} schoolError={schoolError} getSchool={getSchool} setShowDevice={setShowDevice}/>
                                        ) : (
                                            <PinCode studentLogIn={studentLogIn} pinRef={pinRef} loggingIn={loggingIn} formErrors={formErrors} clearSchoolCode={clearSchool} school={school} />
                                        )

                                    }
                                </Fragment>
                            )
                        }

                    </div>
                </div>
            </div>
        </Fragment>

    )
}

export default RedirectAfterLogin;
