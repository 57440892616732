import React from 'react'

const Alert = ({onClose, title, className, message}) => {
    return (
        <div 
            onClick={(event) => {
                if (event.target.classList.contains('confirm-overlay')) {
                    onClose();
                }
            }} 
            className={`confirm-overlay ${className}`}
        >
            <div className="confirm-overlay__body">
                
                <button onClick={onClose} className="confirm-overlay__close-button">
                    <span className="confirm-overlay__close-icon">x</span>
                </button>
                {title && <p className="confirm-overlay__title">{title}</p>}
                {message}
                <div className="confirm-overlay__buttons">
                    <button 
                        className="button button--bordered confirm-overlay__button" 
                        onClick={() => {
                            onClose();
                        }}
                    >
                        Ok
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Alert