import React, { useEffect, useContext, useState } from 'react'
import ls from 'local-storage';
import { useApolloClient, useLazyQuery } from '@apollo/react-hooks';
import { getAppTokenName } from '@axeedge/go-shared-utils';
import { APP_NAME, APP_NAME_US } from '../../../services/constants';
import { AuthContext } from '../../../services/auth/AuthProvider';
import { LOGIN } from '../../../components/Impersonate/services/graphql';
import { navigate } from '@reach/router'
import { parse } from 'query-string';
import { useLocation } from 'react-use';
import styles from '../../Login/Login.module.scss';

const WondeLoggingIn = () => {

    const auth = useContext(AuthContext);
    const client = useApolloClient();

    const location = useLocation();

    const [getStudent, { loading }] = useLazyQuery(LOGIN, {
        onCompleted: (data) => {
            if (data.currentStudent) {
                ls('go_student_user', JSON.stringify(data.currentStudent));
                auth.setCurrentUser(data.currentStudent);
                client.writeData({
                    data: {
                        loggedIn: true,
                        forcedLogout: false
                    }
                });
                navigate('/');
            }
        },
        fetchPolicy: 'network-only'
    });

    useEffect(() => {
        if (parse(location.search).token) {
            const tokenInUrl = parse(location.search).token;
            ls.remove('go_student_user');
            ls.remove(getAppTokenName(ls('country') === 'US' ? APP_NAME_US : APP_NAME));
            auth.setCurrentUser(null)
            ls(getAppTokenName(ls('country') === 'US' ? APP_NAME_US : APP_NAME), tokenInUrl)
            getStudent();
        }
    }, []);

    return (
        <div className={styles.loginWrapper}>
            <div className={styles.loginWrapperMain}>
                <div className={styles.login}>
                    <div className={styles.loginPanel}>
                        <div className={styles.loginPanelContent}>
                            {loading && <p className='u-p-top-3 u-p-base-3'>Logging in ...</p>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WondeLoggingIn;