import { gql } from 'apollo-boost';

export const BOOMER_STORE_QUERY = gql`
query myAvatar {
    currentStudent {
        id
        avatarJson
        avatarUrl
        boomerAvatarJson
        boomerAvatarUrl
        availableGems
        avatarItems {
            id
            gender
            tileHeight
            type
            url
            xmlId
            xOffset
            yPos
        }
    }
}
`

export const GET_BOOMER_QUERY = gql`
query myAvatar {
    currentStudent {
        id
        boomerAvatarUrl
        updatedAt
    }
}
`

export const ALL_ITEMS_QUERY = gql`
query allItemsQuery {
    avatarItems {
        id
        gender
        price
        type
        url
        xmlId
        xOffset
        yPos
    }
}
`;

export const GEMS_QUERY = gql`
query myGems {
    currentStudent {
        id
        availableGems
    }
}
`

export const GET_OWNED_TILES = gql`
query tilesForItem($avatarItemId: ID!) {
    tilesForItem(avatarItemId: $avatarItemId)
}
`

export const BUY_BOOMER_ITEM = gql`
mutation buyBoomerItem($itemXmlId: ID!, $gender: Int, $itemType: Int, $tileId: Int) {
    buyAvatarItem(itemXmlId: $itemXmlId, gender: $gender, itemType: $itemType, tileId: $tileId) {
        errors
        avatarItems {
            id
            gender
            tileHeight
            type
            url
            xmlId
            xOffset
            yPos
        }
    }
}
`;

export const SAVE_BOOMER_ITEMS = gql`
mutation saveBoomerItems($items: [AvatarItemAttributes!]!, $data: String!) {
    saveCurrentAvatarItems(items: $items, data: $data) {
        errors
        student {
            id
        }
    }
}
`;