import React, { Fragment } from 'react';
import _ from 'lodash';

import check from '../../../images/check-green.png';
import lock from '../../../images/lock.png';
import ItemPreview from './ItemPreview';

const Badges = ({ badges, onSelectedBadge, selected }) => (
    <Fragment>
        { _.map(_.orderBy(badges, ['earnedOn'] , ['asc']), badge => {
            return (
                <li key={badge.id} className={`store__badge ${!badge.earnedOn && 'store__badge--disabled'} ${selected === badge.id ? 'store__badge--active' : null}`} onClick={() => badge.earnedOn && onSelectedBadge(badge.id, badge.url)}>
                    <ItemPreview src={badge.url} className="badge__img" />
                    <div className="badge__body">
                        <h2 className="u-m-base-5">{badge.levelName}</h2>
                        {badge.earnedOn ? <p className="u-pill u-pill--success u-m-base-5 u-pill--light-text"><img className="u-pill__image" src={check} alt="check" />{badge.status}</p> : <p className="u-pill u-m-base-5 u-pill--grey u-pill--light-text">{badge.status}</p>}
                        {badge.equipped && <p className="badge__equipped">Equipped</p>}
                        {!badge.earnedOn &&  <p className="badge__quali"><img src={lock} alt="Locked" className="badge__lock" />{badge.message} to earn this badge</p>}
                    </div>
                </li>
            )
        })}
    </Fragment>
);


export default Badges;
