import { gql } from "apollo-boost";

export const CHECK_SURVEYS = gql`
query surveys {
    surveys {
        id
        title
        academicYear
        endAt
    }
}
`;

export const CHECK_STUDENT_SURVEYS = gql`
query currentStudent {
    currentStudent {
        id
        studentSurveys {
            id
            status
            survey {
                id
            }
        }
    }
}
`;

export const GET_SURVEYS = gql`
query surveys {
    surveys {
        id
        title
        academicYear
        endAt
        surveyQuestions {
            id
            orderId
            questionText
            questionType
            questionChoices {
                id
                orderId
                answerText
            }
        }
    }
}
`;

export const STUDENT_SURVEYS = gql`
query currentStudent {
     currentStudent {
        id
        studentSurveys {
            id
            progress
            status
            nextQuestion {
                id
                orderId
                questionText
                questionType
                maxAnswers
                minAnswers
                questionChoices {
                    id
                    orderId
                    answerText
                }
            }
            survey {
                id
                title
                endAt
                surveyQuestions {
                    id
                }
            }
            studentResponses {
                id
                questionChoices {
                    id
                    answerText
                }
                surveyQuestion {
                    id
                    questionText
                }
            }
        }
    }
}
`;


export const START_STUDENT_SURVEY = gql`
mutation createStudentSurvey($studentId: ID!, $surveyId: ID!) {
     createStudentSurvey(studentId: $studentId, surveyId: $surveyId) {
        errors
        studentSurvey {
            id
            progress
            status
            nextQuestion {
                id
                orderId
                questionText
                questionType
                maxAnswers
                minAnswers
                questionChoices {
                    id
                    orderId
                    answerText
                }
            }
            survey {
                id
                title
                endAt
            }
        }
    }
}
`;


export const ADD_STUDENT_RESPONSE = gql`
mutation addStudentResponse($surveyQuestionId: ID!, $studentSurveyId: ID!, $questionChoiceIds: [ID!]!) {
     addStudentResponse(surveyQuestionId: $surveyQuestionId, studentSurveyId: $studentSurveyId, questionChoiceIds: $questionChoiceIds) {
        errors
        studentSurvey {
            id
            progress
            status
            nextQuestion {
                id
                orderId
                questionText
                questionType
                maxAnswers
                minAnswers
                questionChoices {
                    id
                    orderId
                    answerText
                }
            }
            survey {
                id
                title
                endAt
            }
            studentResponses {
                id
                questionChoices {
                    id
                    answerText
                }
                surveyQuestion {
                    id
                    questionText
                }
            }
        }
    }
}
`;