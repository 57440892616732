import React from 'react';
import { ActionCableConsumer, ActionCableProvider } from '@thrash-industries/react-actioncable-provider';
import { getCableEndpoint, getBoomMathCableEndpoint } from '@axeedge/go-shared-utils';
import ls from 'local-storage';

export const ActionCableWrapper = ({ children }) => {

    return (
        <ActionCableProvider url={ls('country') === 'US' ? getBoomMathCableEndpoint() : getCableEndpoint()}>
            {children}
        </ActionCableProvider>
    );
};


export const NotifCableConsumer = ({ id, onReward, onLogout }) => {
    return (
        <ActionCableConsumer
            channel={{
                channel: 'NotificationsChannel',
                id
            }}
            onReceived={(message) => {
                if (message.logged_out) {
                    onLogout();
                }

                if (message.reward && onReward) {
                    onReward(message.reward);
                }
            }}
        ></ActionCableConsumer>
    )
}