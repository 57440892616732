import React, { Fragment } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { GET_OWNED_TILES } from '../services/graphql';
import ItemPreview from './ItemPreview';
import Coins from '../../Dashboard/images/gem.svg';

const Tiles = ({ item, onRemoveItem, onSelectTile}) => {
    const { data, error, loading } = useQuery(GET_OWNED_TILES, {
        variables: {
            avatarItemId: item.id
        },
        fetchPolicy: 'network-only'
    });
    if (loading) {
        return <p>Loading</p>
    }
    if (error) {
        return <p>Something went wrong</p>
    }
    if (data) {
    return (
        <Fragment>
            <li className="store__item-price"><p className="store__item-desc">{item.type}</p><img src={Coins} className="store__item-coins" alt="$"/><p>{item.price}</p></li>
            {
                _.map(item.url, (tile, index) => {
                    return (
                        <li key={`tile-${item.id}-${index}`} onClick={() => onSelectTile(index + 1, data.tilesForItem.includes(index + 1))} className={`store__item ${Number(item.tile) === index + 1 ? 'store__item--wearing' : ''}`}>
                            <ItemPreview src={tile} className="store__item-img" />
                            {data.tilesForItem.includes(index + 1) ? <p className="store__item-owned">Owned</p> : null }
                        </li>
                    )
                })
            }
            {
                item.owned && item.wearing ? 
                <li onClick={() => onRemoveItem()} className={`store__item ${Number(item.tile) === -1 ? 'store__item--wearing' : ''}`}><span className="store__item-img store__item-img--remove">Remove</span></li> :
                null
            }
        </Fragment>
    );
    }
    return null
}

export default Tiles;