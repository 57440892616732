// Queries and mutations for the Home module
import { gql } from 'apollo-boost';

export const LOGGED_IN = gql`
    {
        loggedIn @client
        error @client
    }
`;

// Has the user been 401 logged out
export const FORCED_LOGOUT = gql`
    {
        forcedLogout @client
    }
`;

// Has the client thrown an error
export const ERROR = gql`
    {
        error @client
    }
`;

export const CURRENT_STUDENT_QUERY = gql`
query currentStudent {
    currentStudent {
        id
        active
        activeReadingBooksCounter
        activeWritingBooksCounter
        availableGems
        avatarJson
        firstName
        lastName
        boomerAvatarUrl
        boomerAvatarJson
        name
        loginCode
        expiredToken
        updatedAt
        school {
            id
            canAccessGoReadApp
        }
        studentRewardCards {
            id
            rewardCard {
                id
                chapterWinnerOnly
                pack
            }
        }
        classes {
            id
            name
        }
    }
}
`;
