import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { getClient } from '@axeedge/go-shared-utils';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import ls from 'local-storage';

import { ErrorPage } from '@axeedge/go-shared-components';

import { AuthProvider } from '../../services/auth/AuthProvider';
import { APP_NAME } from '../../services/constants';
import RouterAuthQuery from './components/RouterAuthQuery';
//import useGeoLocation from "react-ipgeolocation";

const hostname = window && window.location && window.location.hostname;

Bugsnag.start({
    apiKey: '112ef5bd7b0c7c164caf36e3c5198c23',
    plugins: [new BugsnagPluginReact()],
    metadata: {
        app: 'Pupil Portal',
        releaseName: hostname === 'localhost' ? 'development' : !hostname.includes('staging') ? 'production' : 'staging'
    },
    enabledReleaseStages: ['production', 'staging'],
    onError: (event) => {
        const user = JSON.parse(ls('go_student_user'));
        if (user && user.id && user.name) {
            event.setUser(user.id, '', `${user.name}`);
        } else {
            event.setUser('unknown', 'unknown', 'unknown');
        }
    }
});

const ErrorBoundary = Bugsnag.getPlugin('react')
    .createErrorBoundary(React)

const Home = () => {

    // const params = new Proxy(new URLSearchParams(window.location.search), {
    //     get: (searchParams, prop) => searchParams.get(prop),
    // });

    // const location = useGeoLocation();
    // //ls.clear()

    // useEffect(() => {
    //     if (params.region) {
    //         ls('country', params.region.toLocaleUpperCase())
    //     } else if (location.country && !ls('country')) {
    //         ls('country', location.country === 'US' ? 'US' : location.country === 'GB' ? 'GB' : 'OTHER')
    //     }
    // }, [location])

    // const getLocation = () => {
    //     if (ls('country') === 'US' || params.region && params.region.toLocaleUpperCase() === 'US') {
    //         return APP_NAME_US
    //     }
    //     if (ls('country') === 'GB') {
    //         return APP_NAME
    //     }
    //     if (location.country === 'US') {
    //         return APP_NAME_US
    //     }
    //     return APP_NAME
    // }

    return (
        <ErrorBoundary FallbackComponent={ErrorPage}>
            <ApolloProvider client={getClient(APP_NAME)} >
                <AuthProvider>
                    <RouterAuthQuery />
                </AuthProvider>
            </ApolloProvider>
        </ErrorBoundary>

    )
}

export default Home;